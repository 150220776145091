var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "right-menu" },
    [
      _c(
        "div",
        { staticClass: "selectAll" },
        [
          _c(
            "a-checkbox",
            {
              attrs: { checked: _vm.isAllSelect, disabled: _vm.allDisabled },
              on: { change: _vm.checkAll },
            },
            [_vm._v(" 全选 ")]
          ),
        ],
        1
      ),
      _vm._l(_vm.selectInfoList, function (item) {
        return _c(
          "div",
          {
            key: item.staffId,
            class: [
              "personList",
              { "enter-person": item.staffId === _vm.mouseenterId },
              { "person-click": item.staffId === _vm.clickId },
            ],
            staticStyle: { cursor: "pointer" },
            on: {
              mouseenter: function ($event) {
                return _vm.enter(item.staffId)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "name_box" },
              [
                _c("a-checkbox", {
                  ref: "checkboxGroup",
                  refInFor: true,
                  attrs: { checked: item.checked },
                  on: {
                    change: function ($event) {
                      return _vm.onChange(item, $event)
                    },
                  },
                }),
                _c(
                  "p",
                  {
                    staticClass: "name_span",
                    on: {
                      click: function ($event) {
                        return _vm.clickInfo(item)
                      },
                    },
                  },
                  [_vm._v(_vm._s(item.staffName))]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "personList-icon" },
              [
                item.staffId === _vm.mouseenterId
                  ? _c("a-icon", {
                      attrs: { type: "close-circle", theme: "filled" },
                      on: {
                        click: function ($event) {
                          return _vm.deletePerson(item)
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        )
      }),
      _c(
        "p",
        [
          _vm.confirmShow
            ? _c(
                "a-button",
                {
                  staticClass: "confirmBatch",
                  attrs: { type: "primary" },
                  on: { click: _vm.confirmBatch },
                },
                [_vm._v("确认开具")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }