var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "PreviewEmployeeInfo" },
      [
        _c(
          "div",
          { staticClass: "PreviewEmployeeInfo-header flex-align-center" },
          [
            _c(
              "div",
              [
                _c("HeaderBack", {
                  attrs: { title: "预览并开具证明" },
                  on: {
                    back: function ($event) {
                      return _vm.$emit("back")
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "header-right" },
              [
                _c(
                  "a-space",
                  [
                    _vm.detailsInfo.onlyRead
                      ? _c(
                          "a-button",
                          {
                            staticClass: "print-btn",
                            attrs: { type: "primary" },
                            on: { click: _vm.exportTemplate },
                          },
                          [_vm._v(" 下载 ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.isEditTemp !== "havePerson" && !_vm.detailsInfo.onlyRead
                  ? _c(
                      "a-button",
                      {
                        staticClass: "confirm-btn",
                        attrs: { type: "primary" },
                        on: { click: _vm.confirmBtn },
                      },
                      [_vm._v("确认开具")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _c("a-divider"),
        _c("div", { staticClass: "PreviewEmployeeInfo-content" }, [
          _c("div", { staticClass: "PreviewEmployeeInfo-content-left" }, [
            !_vm.isEdit
              ? _c("div", { staticClass: "temContent" }, [
                  _c(
                    "div",
                    { staticClass: "v-title" },
                    [
                      _vm.isEditTemp != "details" && _vm.showEdit
                        ? _c(
                            "a-button",
                            {
                              attrs: { type: "link" },
                              on: { click: _vm.editInfo },
                            },
                            [_vm._v("编辑")]
                          )
                        : _c("div", { staticClass: "empty_box" }),
                      _c("a-divider"),
                    ],
                    1
                  ),
                  _c("div", {
                    ref: "printTemplate",
                    staticClass: "printTemplate",
                    domProps: { innerHTML: _vm._s(_vm.content) },
                  }),
                ])
              : _vm._e(),
            _vm.isEdit
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticClass: "v-title-can" },
                      [
                        _c(
                          "a-button",
                          {
                            attrs: { type: "link" },
                            on: { click: _vm.handleCance },
                          },
                          [_vm._v("取消")]
                        ),
                        _c(
                          "a-button",
                          {
                            attrs: { type: "link" },
                            on: { click: _vm.handleOk },
                          },
                          [_vm._v("保存")]
                        ),
                      ],
                      1
                    ),
                    _c("Tinymce-vue", {
                      ref: "tinymceVue",
                      staticClass: "tinymce-vue",
                      attrs: { value: _vm.content, setting: _vm.setting },
                      on: {
                        input: function (res) {
                          return (_vm.content = res)
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _vm.isEditTemp === "havePerson"
            ? _c(
                "div",
                { staticClass: "PreviewEmployeeInfo-content-right" },
                [
                  _c("SelectPerson", {
                    attrs: {
                      "select-info": _vm.selectInfo,
                      "temp-id": _vm.tempId,
                    },
                    on: {
                      getStaffId: _vm.getStaffId,
                      edit: _vm.edit,
                      getInfo: _vm.getInfo,
                      unShow: _vm.unShow,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }